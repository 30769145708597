import logo from './logo.svg';
import './App.css';
import { Wall } from './wall/wall.component';
import { Alert, Box, Button, Card, CardContent, Chip, Container, InputAdornment, Paper, Step, StepButton, Stepper, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { WallInput } from './wall/wall-input.component';
import { CustomerInfo } from './customerinfo/customerinfo.component';
import { Order } from './order/order.component';

function App() {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [beamList, updateBeamList] = useState([]);
  const [values, setValues] = useState({

    height: 150,
    thickness: 150,

    width: 1700,
    angleA: 90,
    angleB: 90,
    angleC: 0,


    angleD: 90,
    angleE:90,

  });


  const handleChangeForm = (event) => {
    console.log(event);
    setValues({ ...values, [event.target.name]: Number(event.target.value) });
  };

  const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    boxShadow: 'none',
  }));

  const steps = ['Bepaal afmetingen', 'Uw gegevens', 'Bevestig bestelling'];

  const addBeam = () => {
    // add beam to the list
    console.log(beamList)
    updateBeamList(beamList.concat([values]));
    setValues({
      height: 150,
      thickness: 150,
      width: 1700,
      angleA: 90,
      angleB: 90,
      angleC: 0,
      angleD: 90,
      angleE:90,
  
    })

  }

  const renderTab = () => {
    switch (activeStep) {
      case 0:
        return <WallInput beamList={beamList} addBeam={addBeam} handleChangeForm={handleChangeForm} values={values}></WallInput>;

      case 1:
        return <CustomerInfo></CustomerInfo>;

      case 2:
        return <Order values={values} beamList={beamList}></Order>;
    }
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <Container maxWidth="xl">

      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Chip className="jello-horizontal" key={beamList.length} label={'Balken : ' + Math.round(beamList.length)} variant="outlined" />        
        <Box sx={{ flex: '1 1 auto' }} />

      </Box>

      <Stepper sx={{ my: 2 }} nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={() => null}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>

      <Box sx={{ display: 'flex', my: 2, flexDirection: 'row', pt: 2 }}>
        <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
          Back
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button onClick={handleNext} variant="contained">
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </Box>

      <div className="flexbox">
        <div className="inputpage">{renderTab()}</div>

        <div className="displaypage">
          {!(values.width > 0 && values.height > 0 && values.thickness > 0) ? (
            <div style={{ position: 'absolute' }}>
              <Alert sx={{ m: 2, width: 1 }} severity="warning">
                Insert correct values
              </Alert>
            </div>
          ) : (
            ''
          )}
          <Wall height={values.height/10} width={values.width/10}  thickness={values.thickness/10} angleA={values.angleA} angleB={values.angleB} angleC={values.angleC} angleD={values.angleD} angleE={values.angleE} ></Wall>
        </div>
      </div>
    </Container>
  );
}

export default App;
