import {
  Alert,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  InputAdornment,
  ListSubheader,
  Paper,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import { Box } from "@mui/system";

import fixy from "fixy";
import { to_bvx, to_file } from "../bvxconvertor/tobvx";

export const Order = ({ values, beamList }) => {
  const data = [
    {
      Partnumber: "000001",
      Partname: "Rafter"
    },
  ];

  const download = () => {
    console.log("download file!");
    var x = fixy.unparse(
      [
        {
          name: "Partnumber",
          width: 7
        },
        {
          name: "Partname",
          width: 20
        },
      ],
      data
    );

    console.log(x);
    to_file (beamList)


  };

  return (
    <Card variant="outlined">
      <CardContent sx={{}}>
        <Typography gutterBottom variant="h5" component="div">
          Bevestig uw order
        </Typography>
        <Typography variant="body2">
          Download hier onder uw Hundegger BVN file.
        </Typography>
        <Box>
          <Button
            onClick={download}
            sx={{ my: 2, width: 1 }}
            variant="contained"
          >
            Download!
          </Button>
        </Box>

        <Typography sx={{ my: 2 }} variant="caption">
          Heb je nog vragen? <a>Contacteer ons!</a>
        </Typography>
      </CardContent>
    </Card>
  );
};
