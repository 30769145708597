


export const to_file = (beamList) => {



let content = [
    `BVINFO application: Demo project ` + '\n',
    `BVINFO date: 21.06.2023, 11:53:16 ` + '\n',
    `BVINFO project: Demo project` + '\n',
]


beamList.forEach((b,i) => {
    content = content.concat(to_bvx(b.angleA, b.angleB, b.height, b.thickness,b.angleD, b.angleE, b.width,i+1))
});


  
  // file object
  const file = new Blob(content, {type: 'text/plain'});

  // anchor link
   const element = document.createElement("a");
   element.href = URL.createObjectURL(file);
   element.download = "export-" + Date.now() + ".BVN";

   // simulate link click
   document.body.appendChild(element); // Required for this to work in FireFox
   element.click();



}

const leading_zeros = (value,length) => {
    const d = length - value.toString().length;
    const pre = "0".repeat(d);
    console.log(pre)
    console.log(pre + "" + value);
    return pre + "" + value;
}


const to_bvx = (angleA, angleB, width, height, angleD, angleE,length,i) => {
    const content = [
        `${leading_zeros(i,6)}              ${leading_zeros(i,5)}-BEAM                     DOU               Be113                                   ` + '\n',
        `${leading_zeros(i,6)}                1       0   ${leading_zeros(Number(width)*10,5)}   ${leading_zeros(Number(height)*10,5)}  ${leading_zeros(length*10,6)}` + '\n',
        `${leading_zeros(i,6)} 0100 +3        0       0       0    ${leading_zeros(Number(angleA)*10,4)}    ${leading_zeros(Number(angleB)*10,4)}                                         000            ` + '\n',
        `${leading_zeros(i,6)} 0100 -3   ${leading_zeros(length*10,6)}       0       0    ${leading_zeros(Number(angleD)*10,4)}    ${leading_zeros(Number(angleE)*10,4)}                                         000            ` + '\n',
    ]

    console.log(content)
    return content;
}
