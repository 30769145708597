import { Box, Button, ButtonGroup, IconButton } from '@mui/material';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import { DrawWall3D } from './wall-babylon';

export const Wall = ({ width, height, stud_spacing, thickness, angleA, angleB,angleC, angleD,angleE }) => {
  const number_of_common_studs = Math.round(width / stud_spacing) + 1;
  const number_of_noggings = number_of_common_studs - 1;

  return (
    <div>
      <div style={{ position: 'absolute' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& > *': {
              m: 1,
            },
          }}>
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <IconButton aria-label="delete">
              <ControlCameraIcon />
            </IconButton>
          </ButtonGroup>
        </Box>
      </div>

      <DrawWall3D  thickness={thickness} width={width} height={height} angleA = {angleA}  angleB = {angleB}  angleC = {angleC} angleD={angleD} angleE={angleE}  />
    </div>
  );
};
