import React, { useEffect, useRef, useState } from "react";
import {
  Engine,
  Scene,
  useBeforeRender,
  useClick,
  useHover,
} from "react-babylonjs";
import {  Color3, Color4, Animation, MeshBuilder, CSG, ArcRotateCamera, StandardMaterial, Texture, Vector4, AxesViewer } from "@babylonjs/core";
import { FreeCamera, Vector3, HemisphericLight } from '@babylonjs/core';

import imagem from "./wood.jpeg";
import insulationtexture from "./insulation.jpeg";
import gyproctexture from "./gyproc.jpeg";
import SceneComponent from 'babylonjs-hook';

let scene;
let beam;
let mat;
let plane;
let planeB;
let axes;
let camera;


// focus = 0 - Begin balk
// focus = 1 - eine balk!

export const DrawWall3D = ({ width,height,thickness, angleA = 90,angleB = 90,angleC = 0, angleD=90, angleE=90, focus=0 }) => {

  

  useEffect(x=>{
    update_beam()
  
  },[width,height,thickness,angleA,angleB,angleC,angleD,angleE])


const deg_to_rad = (deg) =>{
  return deg / 360 * 2 * Math.PI;
}

const update_beam = () => {
  if(scene)  {
    update_mat();
    if(beam){
    beam.dispose();
    }
    const box = MeshBuilder.CreateBox("box", {width: thickness, height:height, depth:100000}, scene);
    box.material = mat;
 
    var bCSG = CSG.FromMesh(box);
 
    
    const length_plane = 350

    // Begin of beam

    // AngleA = Angle
    // AngleB = Bevel
   plane = MeshBuilder.CreatePlane("plane", {height:length_plane, width:length_plane},scene);
   plane.material = mat;
   plane.position = new Vector3(+thickness/2,+1*height / 2,+width/2)
   plane.rotation =new Vector3(1*deg_to_rad(-(90-angleA)),deg_to_rad(-(90-angleB)) , deg_to_rad(0));
   const planeCSG = CSG.FromMesh(plane);

   axes.update(plane.position,new Vector3(0,0,-1), new Vector3(-1,0,0), new Vector3(0,-1,0)) //all parameters are Vector3s


   // End of beam
   planeB = MeshBuilder.CreatePlane("plane", {height:length_plane, width:length_plane, sideOrientation: 1}, scene);
   planeB.material = mat;
   planeB.position = new Vector3(+thickness/2,+1*height / 2,-width/2)
   planeB.rotation =new Vector3(deg_to_rad(+(90-angleD)),deg_to_rad(+(90-angleE)) , deg_to_rad(0));
   const planeBCSG = CSG.FromMesh(planeB);



   var dif = bCSG.subtract(planeCSG).subtract(planeBCSG);
   beam = dif.toMesh("csg",mat, scene,false);
   beam.showBoundingBox = true;
   
   scene.getBoundingBoxRenderer().frontColor = new Color3(0,0,0);
   //scene.getBoundingBoxRenderer().backColor = new Color3(0.8, 0.8, 0.8);
   scene.getBoundingBoxRenderer().showBackLines = false;
   

    plane.dispose();
    planeB.dispose();
    box.dispose();

    
    //camera.setTarget(focus == 0 ? plane.position : planeB.position);


  }
}

const update_mat = () => {
  mat = new StandardMaterial("hout", scene);
  const woodTexture =  new Texture("./wood.jpeg",scene);

  mat.diffuseTexture =woodTexture;

  mat.specularColor=new Color3(0,0,0);
  mat.specularPower = 150;
  mat.backFaceCulling = true;
}


const onSceneReady = (sc) => {
  scene = sc;
  console.log('ok scene!')
  scene.autoClear = false;

  update_mat();

  const light1 = new HemisphericLight("light1",Vector3.Up(),scene);
  light1.diffuse = new Color3(255, 255, 255);
  light1.specular = new Color3(0,0,0);
  light1.groundColor = new Color3(0,0,0);
  const light2 = new HemisphericLight("light2",Vector3.Down(),scene);
  light2.diffuse = new Color3(255, 255, 255);
  light2.specular = new Color3(0,0,0);
  light2.groundColor = new Color3(0,0,0);

   // This creates and positions a free camera (non-mesh)
   camera = new ArcRotateCamera("camera",Math.PI - Math.PI/3 ,Math.PI/2*12/14, 150,Vector3.Zero(),scene) 
   camera.panningSensibility = 10
   const canvas = scene.getEngine().getRenderingCanvas();
 
   // This attaches the camera to the canvas
   camera.attachControl(canvas, true);
 
   // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
   var light = new HemisphericLight("light", new Vector3(0, 1, 0), scene);
 
   // Default intensity is 1. Let's dim the light a small amount
   light.intensity = 0.7;
   axes = new AxesViewer(scene,7);

   // Our built-in 'box' shape.

   

   
update_beam();
  
};


const onRender = scene => {
  if(scene && false ){
    
    var deltaTimeInMillis = scene.getEngine().getDeltaTime()
    const rpm = 1
    beam.rotation.z += (rpm / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000)

    beam.rotation.x += (rpm / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000)

    beam.rotation.y += (rpm / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000)

  }
}

  return (
              <SceneComponent antialias onSceneReady={onSceneReady} id='babylonJS' onRender={onRender}  >      </SceneComponent>
  );
};
