import { Alert, Card, CardContent, Container, InputAdornment, Paper, Step, StepButton, Stepper, TextField, Typography } from '@mui/material';
import { useState } from 'react';

export const CustomerInfo = () => {
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Uw info
        </Typography>
        <Typography variant="body2">Laat uw gegevens achter zodat wij u kunnen contacteren en het product leveren.</Typography>
        <div>
          <TextField
            sx={{ width: 1 }}
            id="outlined-number"
            label="Uw naam"
            type="text"
            margin="normal"
            name="naam"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div>
          <TextField
            sx={{ width: 1 }}
            id="outlined-number"
            label="Uw email"
            type="text"
            margin="normal"
            name="email"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </CardContent>
    </Card>
  );
};
