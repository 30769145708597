import {
  Alert,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Step,
  StepButton,
  Stepper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Box } from 'react-babylonjs';
import { Wall } from './wall.component';

import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

export const WallInput = ({ handleChangeForm, values,addBeam, beamList }) => {


  const [alignment, setAlignment] = useState("left");

  const handleAlignment = (
    event,
    newAlignment
  ) => {
    setAlignment(newAlignment);
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography gutterBottom variant="h4" component="div">
          Stel uw balk samen
        </Typography>

{/*
        <Typography variant="body2">Selecteer het type balk dat u nodig heeft:</Typography><br />


        <ToggleButtonGroup
      value={alignment}
      exclusive
      onChange={handleAlignment}
      aria-label="text alignment"
    >
      <ToggleButton value="left" aria-label="left aligned">
        Recht-Recht
      </ToggleButton>
      <ToggleButton value="center" aria-label="centered">
        Schuin-Recht
      </ToggleButton>
      <ToggleButton value="right" aria-label="right aligned">
        Schuin-Schuin
      </ToggleButton>
      <ToggleButton value="justify" aria-label="justified" >
        Recht-Schuin
      </ToggleButton>
    </ToggleButtonGroup>
*/}
        
<Typography variant="body2">Geef uw afmetingen op:</Typography><br />

        <div>
          <TextField
            sx={{ width: 1 }}
            id="outlined-number"
            label="Breedte balken"
            type="number"
            margin="normal"
            name="thickness"
            value={values.thickness}
            onChange={handleChangeForm}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">mm</InputAdornment>,
            }}
          />
        </div>




        <div>
          <TextField
            sx={{ width: 1 }}
            id="outlined-number"
            label="Hoogte"
            type="number"
            margin="normal"
            name="height"
            value={values.height}
            onChange={handleChangeForm}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">mm</InputAdornment>,
            }}
          />
        </div>




<Typography variant="body2">Begin balk:</Typography><br />

<div>
          <TextField
            sx={{ width: 1 }}
            id="outlined-number"
            label="Hoek begin (0.2° - 179.8°)"
            type="number"
            margin="normal"
            name="angleA"
            value={values.angleA}
            onChange={handleChangeForm}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">°</InputAdornment>,
            }}
          />
        </div>


        <div>
          <TextField
            sx={{ width: 1 }}
            id="outlined-number"
            label="Bevel begin (0.2° - 179.8°)"
            type="number"
            margin="normal"
            name="angleB"
            value={values.angleB}
            onChange={handleChangeForm}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">°</InputAdornment>,
            }}
          />
        </div>

<Typography variant="body2">Einde balk:</Typography><br />



<div>
          <TextField
            sx={{ width: 1 }}
            id="outlined-number"
            label="Afstand tot nulpunt"
            type="number"
            margin="normal"
            name="width"
            value={values.width}
            onChange={handleChangeForm}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">mm</InputAdornment>,
            }}
          />
        </div>


        <div>
          <TextField
            sx={{ width: 1 }}
            id="outlined-number"
            label="Hoek einde (0.2° - 179.8°)"
            type="number"
            margin="normal"
            name="angleD"
            value={values.angleD}
            onChange={handleChangeForm}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">°</InputAdornment>,
            }}
          />
        </div>


        <div>
          <TextField
            sx={{ width: 1 }}
            id="outlined-number"
            label="Bevel einde (0.2° - 179.8°)"
            type="number"
            margin="normal"
            name="angleE"
            value={values.angleE}
            onChange={handleChangeForm}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">°</InputAdornment>,
            }}
          />
        </div><br />
            <div>
        <Button sx={{width:1}} onClick={addBeam} variant="contained">Voeg balk toe ({beamList.length})</Button>
        </div>
{
/*
        <Typography gutterBottom variant="h5" component="div" sx={{ mt: 2 }}>
          Isolatie
        </Typography>
        <Typography variant="body2">Bepaal het type isolatie.</Typography>

        <Select sx={{ width: 1, my: 2 }} name="isolatie_type" labelId="isolatie_type" id="demo-simple-select" value={values.isolatie_type} onChange={handleChangeForm}>
          <MenuItem value={1}>Rockwoll isolatie type I</MenuItem>
          <MenuItem value={2}>Glaswoll isolatie - Wit</MenuItem>
        </Select>
        */

          }

      </CardContent>
    </Card>
  );
};
